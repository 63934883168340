import React, {useState, useEffect, forwardRef} from 'react';
import { makeStyles } from '@material-ui/core/styles';

import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';

import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import DeleteIcon from '@material-ui/icons/Delete';

const useStyles = makeStyles((theme) => ({
	root: {
		flexGrow: 1,
    position: 'absolute',
		top: "200px",
		left:  "50%",
		width: "90%",
		maxWidth: "450px",
		//height: "450px",
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
		transform: "translate(-50%, 0%)",
	},
	
	attachments: {
		overflowY: "auto",
		paddingRight: theme.spacing(1),
		maxHeight: "240px",

	},
	
	deleteButton: {
		margin: theme.spacing(0, 0, 0, -2),
	},
	
	applyButton: {
		margin: theme.spacing(0),
		textAlign: "right",
	},
	
}));

function Attachment(props, ref) {
  const classes = useStyles();
	const {editAttachment, onClose, } = props;
	//const {onClose, info, infoChanged} = props;
	const [info, setInfo] = useState({id: Date.now(), name: "", type:"doc", files: []});
	
	useEffect(() => {
		if (editAttachment!==undefined) setInfo(JSON.parse(JSON.stringify(editAttachment)));
	}, [editAttachment]);
	
	const updateFiles = (add, val) => {
		if (!val) return;
		
		if (add) {
			let exist = info.files.find(element => {
				if (element.name === val.name) return true;
				return false;
			});
			if (!exist) setInfo(prev=>({...prev, files: [...prev.files, {name: val.name, title: val.name, obj: val}]}));
		}
		else setInfo(prev=>({...prev, files: prev.files.filter(item => item !== val)}));
	}
	
	const changeFileTitle = (item, title) => {
		let index = info.files.findIndex(element => {
			if (element.name === item.name) return true;
			return false;
		});
		if (index>-1) {
			info.files[index].title = title;
			setInfo({...info, files: info.files});
		}
	}
	
  return (
	<div className={classes.root} dir="rtl">
	  <React.Fragment>
			<TextField margin="dense" required fullWidth label="שם הנספח" type="text" value={info.name} onChange={(e)=>setInfo({...info, name: e.target.value})} helperText='אנא הקפד למלא שם אינפורמטיבי שמעיד על תוכן הנספח' />
			<TextField select margin="dense" fullWidth label="סוג הנספח" value={info.type} onChange={(e)=>setInfo({...info, type: e.target.value, files: []})}>
        <MenuItem key="doc" value="doc" dir="rtl">מסמך בפורמט pdf</MenuItem>
				<MenuItem key="images" value="images" dir="rtl">צילומי מסך / תמונות</MenuItem>
			</TextField>
			<Box m={2} />
			<div className={classes.attachments}>
				{info.files.map(file => 
				<Grid key={file.name} container spacing={0} >
					<Grid item>
						<IconButton className={classes.deleteButton} onClick={(e)=>updateFiles(false, file)}><DeleteIcon /></IconButton>
					</Grid>
					<Grid item xs>
						<TextField margin="dense" fullWidth required type="text" value={file.title} onChange={(e)=>changeFileTitle(file, e.target.value)} />
					</Grid>
				</Grid>
				)}
			</div>
			<Box m={1} />
			<Button variant="contained" component="label" className={classes.button} startIcon={<CloudUploadIcon />} >העלה קובץ<input type="file" accept={info.type==="doc"?"application/pdf":"image/*"} onChange={(e)=>updateFiles(true, e.target.files[0])} hidden /></Button>
			<div className={classes.applyButton}><Button disabled={info.name==="" || info.files.length===0} variant="contained" color="primary" onClick={()=>onClose(info)} >אישור</Button></div>
		</React.Fragment>

	</div>
  );
}

export default forwardRef(Attachment);