import React, {useState, useLayoutEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';

import Contact from './Contact';
import Protocol from './Protocol';
import About from './About';

const useStyles = makeStyles((theme) => ({

	buttonFotter: {
		margin: theme.spacing(4),
		display: "flex",
		justifyContent: "center",
	},
	
	notice: {
		padding: "0 18px",
	},
	
	formInfo: {
		minHeight: 417,
		padding: theme.spacing(0,1),
	},
	
	grid: {
		padding: theme.spacing(0,1),
	},
	
	title: {
		fontSize: 22,
		[theme.breakpoints.up('md')]: {
      fontSize: 30,
    },
	},
	
	button: {
		borderRadius: theme.spacing(10),
		width: theme.spacing(38),
		height: theme.spacing(6),
		fontSize: "22px",
		margin: "auto",
	},
	
	menu: {
		margin: theme.spacing(0, 1.8),
	},
	
	footer: {
		align: "center",
		position: 'fixed',
		bottom: 15,
		maxWidth: theme.spacing(49),
  },
	
	trajTitle: {
		width: "100%",
		borderRadius: theme.spacing(0),
		padding: theme.spacing(1),
		margin: theme.spacing(0, "auto", ),
		fontSize: "22px",
		
		textAlign: "center",
	},

}));

export default function Start(props) {
  const classes = useStyles();
	const {isUpdate, onStart} = props;
	const [mode, setMode] = useState(0);
	const [modalForm, setModalForm] = useState(0);
	const [btns, setBtns] = useState([]);

	useLayoutEffect(() => {
		setBtns([{title:"כתב תביעה דיגיטלי", op:"tofes"}]);
		if (isUpdate) {
			setBtns([{title:"עידכון כתב התביעה", op:"tofes"}, {title:"עידכון מסלול שירות", op:"trajectory"}, {title:"עידכון פרטי אשראי", op:"payment"}]);
		}
		
	}, [isUpdate]);
  return (
		<React.Fragment>
		<Box mt={2} />
			{mode===0?
			<React.Fragment>
				<div className={classes.formInfo}>
					<Box mt={8} />
					<Typography align="center" variant="h5" className={classes.title}><strong>יצירת והגשת כתב תביעה קטנה באופן דיגיטלי, תוך מספר דקות</strong></Typography>
					<Box mt={6} />
					{btns.map(btn => 
						<div key={btn.op} className={classes.buttonFotter}><Button className={classes.button} variant="contained" color="primary" onClick={()=>onStart(btn.op)}>{btn.title}</Button></div>
					)}
						{!isUpdate &&
						<React.Fragment>
							<Box mt={10} />
							<div className={classes.notice}><Typography style={{ fontSize: 17 }} align="justify" variant="body1">חשוב לציין שעל אף שכתב התביעה נוצר באופן דיגיטלי, מרגע הגשתו לבית המשפט הוא מסמך משפטי לכל דבר ועניין, ולכן הקפידו על <strong>מילוי כל הפרטים באופן מדויק ומהימן.</strong></Typography></div>
						</React.Fragment>
						}
				</div>
				
				<Box mt={1} />
				<Grid className={classes.grid} container direction="row" justify="space-between">
					<Grid item ><Link className={classes.menu} href="#" onClick={()=>setMode(1)}>מסלולים ועלויות</Link></Grid>
					<Divider orientation="vertical" flexItem />
					<Grid item ><Link className={classes.menu} href="#" onClick={()=>setModalForm(1)}>אודות</Link></Grid>
					<Divider orientation="vertical" flexItem />
					<Grid item ><Link className={classes.menu} href="#" onClick={()=>setModalForm(2)}>תקנון</Link></Grid>
					<Divider orientation="vertical" flexItem />
					<Grid item ><Link className={classes.menu} href="#" onClick={()=>setModalForm(3)}>צור קשר</Link></Grid>
				</Grid>
			</React.Fragment>
			:
			<React.Fragment>
				<Link href="#" onClick={()=>setMode(0)}>חזרה</Link>
				<Box mt={2} />
				<Typography align="justify" variant="body1">לאחר מילוי פרטי התביעה תוכלו לבחור בשירות בסיסי להגשה מקוונת של כתב תביעה לבית המשפט או בשירותים משלימים: שירות ייעוץ משפטי או שירות מקיף הניתנים על ידי עורכי דין מנוסים בתחום התביעות הקטנות.</Typography>
				<Box mt={4} />
				<Box className={classes.trajTitle} color="white" bgcolor="primary.main" >שירות בסיסי</Box>
				<Grid className={classes.details}>
					<Grid item><Box mt={1} /><Typography color="primary">מה כולל המסלול?</Typography></Grid>
					<Grid item><Typography align="justify" variant="body2">במסלול הבסיסי אתם ממלאים את הטופס המקוון באתר באופן עצמאי ואנו מבצעים בקרה על נכונות המילוי (לא בקרה משפטית), עורכים את כתב התביעה ומגישים אותו עבורכם לבית המשפט (עותק ערוך של כתב התביעה ישלח בדוא"ל). לאחר מספר ימים נשלח לכם את מספר התיק ואת מועד הדיון וכן הסבר מפורט כיצד ניתן  להתחבר לתיק באמצעות מערכת "נט המשפט" לצורך מעקב אחר התקדמות ההליכים בתיק והגשת מסמכים במידת הצורך.</Typography></Grid>
					<Grid item><Box mt={1} /><Typography color="primary">למי מיועד המסלול?</Typography></Grid>
					<Grid item><Typography align="justify" variant="body2">המסלול הבסיסי מתאים לכל מי שמעוניין להגיש בעצמו תביעה קטנה, באופן פשוט וחסכוני, ומומלץ לאנשים בעלי ניסיון קודם בהגשת תביעות קטנות או כאשר עובדות המקרה ברורות ולא נדרש הסבר משפטי.<br/>ניתן לפנות באמצעות <Link href="#" onClick={()=>setModalForm(3)}>צור קשר</Link> בשאלות לגבי אופן מילוי הנתונים, אך לא יינתן ייעוץ משפטי.</Typography></Grid>
					<Grid item><Box mt={1} /><Typography color="primary">מה עלות המסלול?</Typography></Grid>
					<Grid item><Typography align="justify" variant="body2">עלות המסלול  הבסיסי הינו 350 ש"ח, כולל מע"מ.</Typography></Grid>
					<Grid item><Typography align="justify" variant="body2"><br /><strong>לתשומת ליבכם, שירות זה <u>אינו</u> משפטי! לאחר עריכת כתב התביעה נשלח אותו באימייל לאישורכם. רק לאחר האישור, הוא יוגש לבית המשפט.</strong></Typography></Grid>
				</Grid>
				<Box mt={5} />
				<Box className={classes.trajTitle} color="white" bgcolor="primary.main" >שירות ייעוץ משפטי</Box>
				<Grid className={classes.details}>
					<Grid item><Box mt={1} /><Typography color="primary">מה כולל המסלול?</Typography></Grid>
					<Grid item><Typography align="justify" variant="body2">מסלול הייעוץ המשפטי כולל את כל שלבי המסלול הבסיסי, ובנוסף - עורך דין מנוסה בהליכי תביעות קטנות, יקרא את כתב התביעה ויצור קשר עם בעל הדין כדי לייעץ ולהדריך, הן לגבי ההליך והן לגבי ההיבטים המשפטיים הנוגעים לעובדות התביעה (לשון ופרשנויות החוק, חיזוק טענות, מיקוד הנימוקים וכו').</Typography></Grid>
					<Grid item><Box mt={1} /><Typography color="primary">למי מיועד המסלול?</Typography></Grid>
					<Grid item><Typography align="justify" variant="body2">מסלול הייעוץ מתאים עבור מי שמעוניין לנסח את כתב התביעה בעצמו, אך זקוק לייעוץ והכוונה בהיבטים הטכניים של הליך הגשת התביעה ובהיבטים משפטיים של טיעונים תומכים ומחזקים לנימוקי התביעה.</Typography></Grid>
					<Grid item><Box mt={1} /><Typography color="primary">מה עלות המסלול?</Typography></Grid>
					<Grid item><Typography align="justify" variant="body2">עלות מסלול  הייעוץ המשפטי הינו 550 ש"ח, כולל מע"מ.</Typography></Grid>
					<Grid item><Typography align="justify" variant="body2"><br /><strong>בסיום ההתייעצות, יוכל בעל הדין לתקן את כתב התביעה באופן עצמאי לאור המלצות עורך הדין ואף לקיים התייעצויות נוספות, עד לשביעות רצונו.</strong></Typography></Grid>
				</Grid>
				<Box mt={5} />
				<Box className={classes.trajTitle} color="white" bgcolor="primary.main" >שירות מקיף</Box>
				<Grid  className={classes.details}>
					<Grid item><Box mt={1} /><Typography><strong>הכנה והגשה של כתב תביעה קטנה על ידי עו"ד</strong></Typography></Grid>
					<Grid item><Typography align="justify" variant="body2">בכתב תביעה המוגש לבית המשפט נדרש התובע להציג את טענותיו מהיבטים עובדתיים ומשפטיים. כתב תביעה שערוך באופן מקצועי ומוגש לבית המשפט כדין, מגביר באופן משמעותי את סיכוייו של התובע לזכות בתביעה.</Typography></Grid>
					<Grid item><Box mt={1} /><Typography color="primary">מה כולל המסלול?</Typography></Grid>
					<Grid item><Typography align="justify" variant="body2">במסלול המקיף עורך דין מוסמך ילווה אתכם וייעץ בכל שלבי התביעה, החל מניסוח ועריכת כתב התביעה, דרך הגשתו לבית המשפט לתביעות קטנות וקביעת מועד דיון ועד להכנה טלפונית בסמוך למועד הדיון.</Typography></Grid>
					<Grid item><Box mt={1} /><Typography color="primary">למי מיועד המסלול?</Typography></Grid>
					<Grid item><Typography align="justify" variant="body2">לכל תובע שמעוניין בהגשת כתב תביעה מנומק ומנוסח באופן מקצועי וזקוק לליווי משפטי על ידי עורך דין מיומן.</Typography></Grid>
					<Grid item><Box mt={1} /><Typography color="primary">מה עלות המסלול?</Typography></Grid>
					<Grid item><Typography align="justify" variant="body2">עלות המסלול  המקיף  הינו 700 ש"ח, כולל מע"מ.</Typography></Grid>
					<Grid item><Typography align="justify" variant="body2"><br /><strong>השירותים המשפטיים ניתנים ע"י משרד עו"ד ויסמן סלע, באמצעות <Link href="https://www.din-online.co.il">מרכז "דין אונליין" לשירותים משפטיים מקוונים</Link>.</strong></Typography></Grid>
				</Grid>
				<Box mt={10} />
			</React.Fragment>
			}
			<About open={modalForm===1} onClose={()=>setModalForm(0)} />
			<Protocol open={modalForm===2} onClose={()=>setModalForm(0)} />
			<Contact open={modalForm===3} onClose={()=>setModalForm(0)} />
		</React.Fragment>
  );
}