import React, {useState, useLayoutEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Pros from './Pros';
import Case from './Case';
import Sue from './Sue';
import Attachments from './Attachments';
import Sign from './Sign';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
  root: {
  },

	cardInfo: {
		minHeight: 468,
	},
	
	formInfo: {
		minHeight: 417,
		padding: theme.spacing(0,1),
	},

	title: {
    width: '100%',
    margin: theme.spacing(3.5, 0, 2, 0),
  },

	nav: {
		display: "flex",
		flexDirection: "row",
		justifyContent: "flex-end",
		marginTop: "15px",
	},
	
	navButton: {
		width: "65px",
	},
}));

export default function Tofes(props) {
  const classes = useStyles();
	const {loadedInfo, onCompleted} = props;
	const [info, setInfo] = useState({pros: [], resp: [], attachments: []});
	const [declaration, setDeclaration] = useState({});
	const [valid, setValid] = useState({pros: [], resp: []});
	const [check, setCheck] = useState(false);
	const [mode, setMode] = useState(0);
	const [btnTitle, setBtnTitle] = useState("הבא");
	const titles = ["פרטי התובע/ים", "פרטי הנתבע/ים", "נימוקי כתב התביעה", "פרטי התביעה", "צירוף נספחים", "הצהרת וחתימת התובע"];
	const [tabDisplayed, setTabDisplayed] = useState([0, 0]);
	
	//useEffect(() => {
  useLayoutEffect(() => {
		
		var info = {pros: [], resp: [], attachments: []};
		var declaration = {};
		var valid = {pros:[], resp:[]};
		
		var arr1 = Array.from(Array(2).keys())
		var arr2 = Array.from(Array(3).keys())
		
		if (loadedInfo) {
			info = loadedInfo.tofes;
			
			arr1.forEach(number => {
				valid.pros[number] = {name: true, iid: true, phone: true};
			});
			arr2.forEach(number => {
				valid.resp[number] = {name: true, iid: true, phone: true};
			});
			valid.case = {place:true, info:true};
			valid.sue = {ammount: true, court: true};
			declaration = {a1: true, a2: true, a3: true, signature: loadedInfo.signature?JSON.stringify(loadedInfo.signature):undefined};
			valid.declaration =  {a1: true, a2: true, a3: true, signature: true,};
		} 
		else {
			arr1.forEach(number => {
				info.pros[number] = {enabled: number===0, id: number, name: "", iid: "", address: "", city: null, phone: "", email: ""};
				valid.pros[number] = {name: number!==0, iid: number!==0, phone: number!==0};
			});
			arr2.forEach(number => {
				info.resp[number] = {enabled: number===0, id: number, name: "", personal: true, iid: "", address: "", city: null, phone: "", phonetype:0, email: ""}
				valid.resp[number] = {name: number!==0, iid: number!==0, phone: number!==0};
			});
			info.case = {place: "", placetype: 0, date:"", caseinfotype: 0, caseinfo: "", casefile: undefined, casephone: ""};
			valid.case = {place:false, info:false};
			
			info.sue = {suetype: "ספק - לקוח", ammount: "", fee: 0, expense: 0, court: "בחר"};
			valid.sue = {ammount: false, court: false};
			
			declaration = {a1: false, a2: false, a3: false, signature: undefined};
			valid.declaration =  {a1: false, a2: false, a3: false, signature: false,};
		}
		setInfo(info);
		setValid(valid);
		setDeclaration(declaration);

		setMode(0);
  }, [loadedInfo]);
	
	const btnClicked = (next) => {
		if (next) {
			if (mode<5) setMode(mode+1);
			if (mode===4) setBtnTitle("סיום");
			else if (mode===5) {
				setCheck(true);
				setBtnTitle("הבא");
				var [modefix, tabfix] = getErrorModes();
				//if (true) {
				if (modefix === -1) {
					setTimeout(()=>onCompleted(info, declaration), 0);
				} else {
					setMode(modefix);
					if (tabfix !== -1) {
						updateTabDisplayed(modefix, tabfix);
					}
				}

			}
		} else {
			if (mode===5) setBtnTitle("הבא");
			setMode(mode-1);
		}
	};
	
	const getErrorModes = () => {
		var [found, tabfix] = [false, 0];
		
		valid.pros.forEach(item => {
			if (!found) {
				if (!item.name || !item.iid || !item.phone) found = true;
				else tabfix += 1;
			}
		});
		if (found) return [0, tabfix];

		[found, tabfix] = [false, 0];
		valid.resp.forEach(item => {
			if (!found) {
				if (!item.name || !item.iid || !item.phone) found = true;
				else tabfix += 1;
			}
		});
		if (found) return [1, tabfix];
		
		if (!valid.case.info) return [2, -1];
		if (!valid.case.place || !valid.sue.ammount || !valid.sue.court) return [3, -1];
		if (!valid.declaration.a1 || !valid.declaration.a2 || !valid.declaration.a3 ) return [5, -1];
		return [-1, -1];
	}
	
	const updateObject = (group, newVal) => {
		let list = [...info[group]];
		list[newVal.id] = newVal;
		setInfo({...info, [group]: list});

		list = [...valid[group]];
		list[newVal.id] = {name: !newVal.enabled||newVal.name, iid: !newVal.enabled||(newVal.iid&&(group==="resp"||newVal.iid.length===9)), phone: !newVal.enabled||newVal.phone};
		setValid({...valid, [group]: list});
	};
	
	const updateField = (group, field, newVal) => {
		setInfo(prev=>({...prev, [group]: {...prev[group], [field]: newVal}}));
		if (valid[group].hasOwnProperty(field)) {
			setValid(prev=>({...prev, [group]: {...prev[group], [field]: newVal!==""}}));
		}
		if (group==="case") {
			var infoValid = true;
			var caseinfotype = field==="caseinfotype"?newVal:info.case.caseinfotype;
			var caseinfo = field==="caseinfo"?newVal:info.case.caseinfo;
			var casefile = field==="casefile"?newVal:info.case.casefile;
			if ((caseinfotype===0&&caseinfo==="") || (caseinfotype===1&&casefile===undefined)) infoValid=false;
			if (infoValid!==valid.case.info) setValid({...valid, case: {...valid.case, info: infoValid}});
		}
		if (group==="sue") {
			if (field==="ammount") {
				var ammount = parseInt(newVal);
				var ammountValid = false;
				var fee = 50;
				if (ammount>0 && ammount<=34600) {
					ammountValid=true;
					if (ammount>5000) fee = Math.ceil(ammount/500)*5;
				}
				setInfo(prev=>({...prev, sue: {...prev.sue, fee: fee}}));
				setValid({...valid, sue: {...valid.sue, ammount: ammountValid}});
			} else if (field==="court") {
				setValid({...valid, sue: {...valid.sue, court: newVal!=="בחר"}});
				
			}
		}
	};		

	const updateDecleration = (field, newVal) => {
		setDeclaration({...declaration, [field]: newVal});
		setValid({...valid, declaration: {...valid.declaration, [field]: newVal}});
	}
	
	const updateAttachments = (add, val) => {
		if (add) {
			let list = [...info.attachments];
			let index = list.findIndex(element => {
				if (element.id === val.id) return true;
				return false;
			});
			if (index > -1) list[index] = val;
			else list.push(val);
			setInfo({...info, attachments: list});
		}
		else setInfo(prev=>({...prev, attachments: prev.attachments.filter(item => item.name !== val.name)}));
	}
	
	const updateTabDisplayed = (index, val) => {
		let newArr = [...tabDisplayed];
    newArr[index] = val;
    setTabDisplayed(newArr);
	}
	
  return (
	<React.Fragment>
		<div className={classes.formInfo}>
			<Typography className={classes.title} variant="h6">{titles[mode]}</Typography>
			{mode===0?
			<Pros type={0} check={check} valid={valid.pros} displayed={tabDisplayed[0]} info={info.pros} infoChanged={val=>updateObject("pros", val)} tabChanged={val=>updateTabDisplayed(0, val)} />
			:mode===1?
			<Pros type={1} check={check} valid={valid.resp} displayed={tabDisplayed[1]} info={info.resp} infoChanged={val=>updateObject("resp", val)} tabChanged={val=>updateTabDisplayed(1, val)} />
			:mode===2?
			<Case check={check} valid={valid.case} info={info.case} infoChanged={(field, val)=>updateField("case", field, val)} />
			:mode===3?
			<Sue check={check} valid={valid} info={info} infoChanged={(group, field, val)=>updateField(group, field, val)} />
			:mode===4?
			<Attachments info={info.attachments} infoChanged={(add, val)=>updateAttachments(add, val)} />
			:
			<Sign check={check} valid={valid.declaration} info={declaration} infoChanged={(field, val)=>updateDecleration(field, val)} />
			}
		</div>
		<div className={classes.nav}>
			<Button className={classes.navButton} disabled={mode===0} variant="contained" color="primary" onClick={()=>btnClicked(false)}>חזור</Button>
			<Box m={1} />
			<Button className={classes.navButton} variant="contained" color="primary" onClick={()=>btnClicked(true)}>{btnTitle}</Button>
		</div>
		<Box mt={2} />
		<Typography variant="body2" color="textSecondary" align="center" dir="rtl">{'© כל הזכויות שמורות ל'}<Link color="inherit" href="https://www.small-claims.il">{'תביעות קטנות - פשוט להגיש אונליין'}</Link>{'.'}</Typography>
	</React.Fragment>
  );
}