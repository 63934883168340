import React, {useLayoutEffect, } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import InputAdornment from '@material-ui/core/InputAdornment';
import Grid from '@material-ui/core/Grid';

const suetypes = [{value: 0, label: 'כספית נזקי גוף (ח)'}, {value: 1, label: 'ספק - לקוח'}, {value: 2, label: 'רכב'}, {value: 3, label: 'שכירות'}, {value: 4, label: 'תביעת ספאם'}, {value: 5, label:'תיירות'}, {value: 6, label: 'אחר'}];
const courts = [{value: -1, label: 'בחר'}, {value: 0, label: 'אילת'}, {value: 1, label: 'אריאל'}, {value: 2, label: 'אשדוד'}, {value: 3, label: 'אשקלון'}, {value: 4, label: 'באר שבע'}, {value: 5, label: 'בית שאן'}, {value: 6, label:'בית שמש'}, {value: 7, label: 'בת ים'}, {value: 8, label: 'דימונה'}, {value: 9, label: 'הרצליה'}, {value: 10, label: 'חדרה'}, {value: 11, label: 'חיפה'}, {value: 12, label: 'טבריה'}, {value: 13, label: 'ירושלים'}, {value: 14, label: 'כפר סבא'}, {value: 15, label: 'מסעדה'}, {value: 16, label: 'נצרת'}, {value: 17, label: 'נתניה'}, {value: 18, label: 'עכו'}, {value: 19, label: 'עפולה'}, {value: 20, label: 'פתח תקווה'}, {value: 21, label: 'צפת'}, {value: 22, label: 'קצרין'}, {value: 23, label: 'קריות'}, {value: 24, label: 'קריית גת'}, {value: 25, label: 'קריית שמונה'}, {value: 26, label: 'ראשון לציון'}, {value: 27, label: 'רחובות'}, {value: 28, label: 'רמלה'},  {value: 29, label: 'תל אביב - יפו'}, ];

const useStyles = makeStyles((theme) => ({
	root: {
    flexGrow: 1,
    marginTop: theme.spacing(-2),
  },
	
}));

export default function Sue(props) {
  const classes = useStyles();
	const {check, valid, info, infoChanged} = props

	useLayoutEffect(() => {
		//infoChanged("suetype", suetypes.find(c=>c.value===1).label);
		//infoChanged("court", courts.find(c=>c.value===-1).label);
  }, []);
	
  return (
	<div className={classes.root}>
		<Grid container spacing={2} alignItems="flex-start"><Grid item xs>
			<TextField error={check&&!valid.case.place} margin="dense" required fullWidth id="place" label="מקום הארוע" name="place" helperText="מקום הארוע שבגינו תובעים" value={info.case.place} onChange={(e)=>infoChanged("case", "place", e.target.value)} InputLabelProps={{shrink: true,}}/>
		</Grid><Grid item xs>
			<TextField margin="dense" fullWidth id="date" label="תאריך הארוע" name="date" type="date" helperText="תאריך הארוע שבגינו תובעים" value={info.case.date} onChange={(e)=>infoChanged("case", "date", e.target.value)} InputLabelProps={{shrink: true,}}/>
		</Grid></Grid>
		
	  <TextField select margin="dense" fullWidth label="סוג התביעה" value={info.sue.suetype} onChange={(e)=>infoChanged("sue", "suetype", e.target.value)}>
			{suetypes.map((option) => (
        <MenuItem key={option.value} value={option.label} dir="rtl">{option.label}</MenuItem>
      ))}
		</TextField>
		<TextField error={check&&!valid.sue.ammount} margin="dense" required fullWidth id="ammount" label="סכום התביעה" name="ammount" type="number" value={info.sue.ammount} onChange={(e)=>infoChanged("sue", "ammount", e.target.value)} helperText='הסכום המקסימלי של תביעה קטנה עומד על 34,600 ש"ח' InputProps={{endAdornment: <InputAdornment position="end">ש"ח</InputAdornment>}} />		
			{false && <TextField margin="dense" fullWidth id="expense" label="הוצאות משפטיות" name="expense" type="number" value={info.sue.expense} onChange={(e)=>infoChanged("sue", "expense", e.target.value)} helperText='ניתן להוסיף הוצאות עקיפות: הפסד שכר עבודה, נסיעות, עוגמת נפש וכדומה' InputProps={{endAdornment: <InputAdornment position="end">ש"ח</InputAdornment>}} />}
	  <TextField error={check&&!valid.sue.court} select margin="dense" fullWidth label="בית המשפט בו תוגש התביעה" value={info.sue.court} onChange={(e)=>infoChanged("sue", "court", e.target.value)} helperText="התביעה תוגש במקום מגוריו/עיסוקו של הנתבע, או מקום המעשה שבגינו תובעים. תביעות בנושא פרסום/סחר באינטרנט ניתן להגיש במקום מגוריו/עיסוקו של התובע או הנתבע.">
			{courts.map((option) => (
        <MenuItem key={option.value} value={option.label} dir="rtl">{option.label}</MenuItem>
      ))}
		</TextField>

	</div>
  );
}