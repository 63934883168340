import React from 'react';
import { create } from 'jss';
import rtl from 'jss-rtl';
import { StylesProvider, jssPreset } from '@material-ui/core/styles';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';

import blue from '@material-ui/core/colors/blue';
import pink from '@material-ui/core/colors/pink';

const jss = create({ plugins: [...jssPreset().plugins, rtl()] });

const theme = createMuiTheme({
  palette: {
    primary: {
			main: blue[800],
		},
    secondary: pink,
  },
	direction: 'rtl',
});

export default function RTL(props) {
  return (
	<ThemeProvider theme={theme}>
		<StylesProvider jss={jss}>
			{props.children}
		</StylesProvider>
	</ThemeProvider>
  );
}