import React, {useState, useLayoutEffect} from 'react';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { useParams } from "react-router";

import { makeStyles } from '@material-ui/core/styles';
import axios from 'axios';
import Tofes from './Tofes';
import Start from './Start';
import TrajChoose from './TrajChoose';
import Payment from './Payment';

import { useSnackbar } from 'notistack';

import Box from '@material-ui/core/Box';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import CssBaseline from '@material-ui/core/CssBaseline';


//const formUrl = "https://din-online.co.il/srv/";
const baseUrl = "https://srv.small-claims.co.il/";
const loadUrl = `${baseUrl}load.php`;
const uploadUrl = `${baseUrl}upload.php`;
const trajectories = [{title: 'מסלול בסיסי', price: '350 ש"ח'}, {title: 'מסלול ייעוץ משפטי', price: '550 ש"ח'}, {title: 'מסלול מקיף', price: '700 ש"ח'}];
let id = Math.random().toString(36).substring(2,7);

const useStyles = makeStyles((theme) => ({
  root: {
		
		maxWidth: "500px",
		margin: theme.spacing(0, "auto"),
		//maxWidth: theme.spacing(53),
		padding: theme.spacing(0,2),
		[theme.breakpoints.up('md')]: {
      marginTop: "5%",
    },
  },

	backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
	
	logo: {
		height: "110px",
		maxWidth: "100%",
	},

}));

export default function App() {
	return (
		<Router>
			<Routes>
				<Route path="/" element={<Main />} >
					<Route path="account/:param" element={<Main />} />
				</Route>
				
				<Route path="*" element={
						<main style={{ padding: "1rem" }}>
							<p>There's nothing here!</p>
						</main>
					} />
			</Routes>
   </Router>
	);
}

function Main() {
  const classes = useStyles();
	const { enqueueSnackbar } = useSnackbar();
	const [mode, setMode] = useState('start');
	const [tofes, setTofes] = useState();
	const [fee, setFee] = useState(50);
	const [trajid, setTrajid] = useState(0);
	const [isUpdate, setIsUpdate] = useState(false);
	const [uploadMode, setUploadMode] = useState(false);
	let { param } = useParams();
	
	useLayoutEffect(() => {
		if (param) {
			id = param;
			setIsUpdate(true);
			setUploadMode(true);
			axios.post(`${loadUrl}?id=${param}`)
			.then(res => {
				setUploadMode(false);
				if (res.data.status==="ok") {
					id = param;
					setTofes(res.data);
				} else {
					setIsUpdate(false);
				}
			});
		}
  }, [param]);
	
	const tofesInfo = (tofes, declaration) => {
		setFee(Math.max(50, tofes.sue.ammount/100));
		const fd = new FormData();
		
		if (tofes.case.casefile) fd.append('casefile', tofes.case.casefile.obj);
		tofes.attachments.forEach(attachment => {
			attachment.files.forEach(item => {
				fd.append(item.name, item.obj);
			});
		});
		
		fd.append('id', id);
		fd.append('tofes', JSON.stringify(tofes));
		fd.append('declaration', JSON.stringify(declaration));

		setUploadMode(true);
		axios.post(uploadUrl, fd)
		.then(res => {
			setUploadMode(false);
			if (isUpdate) {
				setMode('start');
				enqueueSnackbar(res.data.msg, { variant: res.data.status });
			}
			else setMode('trajectory');
		});
	};
	
	const trajectoryInfo = (op, param) => {
		const fd = new FormData();
		fd.append('id', id);
		fd.append(op, param);
		axios.post(uploadUrl, fd)
		.then(res => {
			if (isUpdate) enqueueSnackbar(res.data.msg, { variant: res.data.status });
		});
		if (isUpdate) setMode('start');
		else {
			if (op==="trajectory") setMode('payment');
		}
	};
	
  return (
    <div className={classes.root} dir="rtl">
      <CssBaseline />
			<Backdrop className={classes.backdrop} open={uploadMode}><CircularProgress color="inherit" /></Backdrop>
			<Box mt={2} />
			<div className={classes.logo}><img width="100%" src="/logo.png" alt="" /></div>
				{mode==='start'?
				<Start isUpdate={isUpdate} onStart={(op)=>setMode(op)} />
				:mode==='tofes'?
				<Tofes loadedInfo={tofes} onCompleted={(tofes, declaration)=>tofesInfo(tofes, declaration)} />
				:mode==='trajectory'?
				<TrajChoose fee={fee} trajectories={trajectories} onChoose={trajid=>{setTrajid(trajid); trajectoryInfo("trajectory", trajectories[trajid].title)}} />
				:
				<Payment fee={fee} trajid={trajid} trajectory={trajectories[trajid]} paymentFilled={cardInfo=>trajectoryInfo("payment", cardInfo)} />
				}
    </div>
  );
}