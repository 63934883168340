import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';

import Button from '@material-ui/core/Button';

const useStyles = makeStyles((theme) => ({
  para: {
		marginBottom: theme.spacing(1),
  },

	
}));
export default function About(props) {
	const classes = useStyles();
	const {open, onClose} = props;
	

	const handleClose = () => {
    //setOpen(false);
		onClose();
  };
	
  return (
	  <Dialog open={open} dir="rtl">
			<DialogTitle>אודות</DialogTitle>
			<DialogContent>
				<DialogContentText component={'span'}>
					<Typography className={classes.para} align="justify"><strong>"תביעות קטנות - פשוט להגיש אונליין"</strong> הינו מיזם אינטרנטי פרטי-עסקי, שמטרתו לסייע לציבור במימוש זכויותיו החוקיות, לתבוע או להתגונן:  הוא מאפשר להגיש בקלות תביעה קטנה עד גובה של 34,600 ש"ח, ללא צורך בעורך דין או בידע משפטי כלשהו, בהליך קצר ויעיל ו<strong>בעלות סימלית בלבד</strong> (בתוספת אגרת בית משפט עפ"י חוקי מדינת ישראל).</Typography>
					<Typography className={classes.para} align="justify">בנוסף, האתר מציע שירותים משלימים תמורת דמי טיפול, שניתנים ע"י משרד עו"ד ויסמן סלע, באמצעות <Link href="https://din-online.co.il">מרכז "דין אונליין" לשירותים משפטיים מקוונים</Link>.</Typography>
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button color="primary" onClick={handleClose}>סגור</Button>
			</DialogActions>
		</Dialog>
  );
}

