import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Switch from '@material-ui/core/Switch';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import CityField from './CityField';

const useStyles = makeStyles((theme) => ({
	root: {
    flexGrow: 1,
    display: 'flex',
    height: 330,
  },

  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
		width: "65px",
  },

	tab: {
		borderRadius: "0px",
		fontColor: "gray",
		justifyContent: "flex-start",
		paddingLeft: theme.spacing(0),
		height: "50px",
	},

	tab1: {
		borderRadius: "0px",
    borderRight: `2px solid red`,
		width: "50px",
  },

	tabPannel: {
    margin: theme.spacing(-1, 0, 0, 2),
  },

	tabSwitch: {
		marginBottom: theme.spacing(-1),
	}
	
}));

function Tab(props) {
	const {children, id, displayed, tabChanged} = props;
	const classes = useStyles();

	return (
		<Button fullWidth className={classes.tab} style={{color: displayed===id?'black':'gray', borderLeft: displayed===id?'2px solid red':''}} onClick={()=>tabChanged()}>
			{children}
		</Button>
	);
}

function ProsTabPanel(props) {
	const {check, valid, displayed, info, setUser} = props;
	const classes = useStyles();
  
	return (
		<div className={classes.tabPannel} role="tabpanel" hidden={displayed !== info.id}>
			<div className={classes.tabSwitch} hidden={info.id===0}><Switch edge="start" dir="ltr" color="primary" checked={info.enabled} onChange={e=>setUser({...info, enabled: e.target.checked})} />תובע נוסף</div>
			<TextField disabled={!info.enabled} error={check&&!valid.name}  margin="dense" required fullWidth label="שם" name="name" autoComplete="name" value={info.name} onChange={(e)=>setUser({...info, name: e.target.value})} />
			<TextField disabled={!info.enabled} error={check&&!valid.iid} margin="dense" required fullWidth label="מספר זהות" name="iid" type="number" value={info.iid} helperText={(check&&!valid.iid)?"יש להזין מספר ת\"ז בן 9 ספרות, כולל ספרת ביקורת":""} onChange={(e)=>setUser({...info, iid: e.target.value})} />
			<TextField disabled={!info.enabled} error={check&&!valid.phone} margin={(check&&!valid.iid)?"none":"dense"} required fullWidth label="טלפון" name="phone" type="tel" autoComplete="phone" value={info.phone} onChange={(e)=>setUser({...info, phone: e.target.value})} />
			<Grid container spacing={2} alignItems="flex-start"><Grid item xs>
				<TextField disabled={!info.enabled} margin="dense" fullWidth label="כתובת" name="address" autoComplete="address" value={info.address} onChange={(e)=>setUser({...info, address: e.target.value})} />
			</Grid><Grid item xs>
				<CityField disabled={!info.enabled} value={info.city} onCityChange={(val)=>setUser({...info, city: val})} />
			</Grid></Grid>
			<TextField disabled={!info.enabled} margin="dense" fullWidth label="אימייל" name="email" autoComplete="email" value={info.email} onChange={(e)=>setUser({...info, email: e.target.value})} />
			<Box m={2} />
			<Typography className={classes.filesheader} hidden={info.id!==0}><strong>ניתן לצרף תובע ע"י מילוי הטאב הנוסף</strong></Typography>
		</div>
	);
}

function RespTabPanel(props) {
	const {check, valid, displayed, info, setUser} = props;
	const classes = useStyles();
  
	return (
		<div className={classes.tabPannel} role="tabpanel" hidden={displayed !== info.id}>
			<div className={classes.tabSwitch} hidden={info.id===0}><Switch edge="start" dir="ltr" color="primary" checked={info.enabled} onChange={e=>setUser({...info, enabled: e.target.checked})} />נתבע נוסף</div>
			<TextField disabled={!info.enabled} error={check&&!valid.name}  margin="dense" required fullWidth label="שם" name="name" autoComplete="name" value={info.name} onChange={(e)=>setUser({...info, name: e.target.value})} />
			<TextField disabled={!info.enabled} error={check&&!valid.iid} margin="dense" required fullWidth label="מספר זהות/ח.פ." name="iid" type="number" value={info.iid} onChange={(e)=>setUser({...info, iid: e.target.value})} />
			<TextField disabled={!info.enabled} error={check&&!valid.phone} margin="dense" required fullWidth label="טלפון" name="phone" autoComplete="phone" value={info.phone} onChange={(e)=>setUser({...info, phone: e.target.value})} />
			<Grid container spacing={2} alignItems="flex-start"><Grid item xs>
				<TextField disabled={!info.enabled} margin="dense" fullWidth label="כתובת" name="address" autoComplete="address" value={info.address} onChange={(e)=>setUser({...info, address: e.target.value})} />
			</Grid><Grid item xs>
				<CityField disabled={!info.enabled} value={info.city} onCityChange={(val)=>setUser({...info, city: val})} />
			</Grid></Grid>
			<TextField disabled={!info.enabled} margin="dense" fullWidth label="אימייל" name="email" autoComplete="email" value={info.email} onChange={(e)=>setUser({...info, email: e.target.value})} />
			<Box m={2} />
			<Typography className={classes.filesheader} hidden={info.id!==0}><strong>ניתן לצרף נתבעים ע"י מילוי הטאבים הנוספים</strong></Typography>
		</div>
	);
}


export default function Pros(props) {
  const classes = useStyles();
	const {type, check, valid, info, displayed, infoChanged, tabChanged} = props
	const tabTitle = ["תובע", "נתבע"]

  return (
	<div className={classes.root}>
		<div className={classes.tabs} >
			{info.map(item => 
				<Tab displayed={displayed} key={item.id} id={item.id} tabChanged={()=>tabChanged(item.id)}>{tabTitle[type]} {item.id+1}</Tab>
			)}
		</div>
		{type===1? info.map(item => <RespTabPanel key={item.id} check={check} valid={valid[item.id]} displayed={displayed} info={info[item.id]} setUser={item1=>infoChanged(item1)} />)
		:info.map(item => <ProsTabPanel key={item.id} check={check} valid={valid[item.id]} displayed={displayed} info={info[item.id]} setUser={item1=>infoChanged(item1)} />)}
	</div>
		

  );
}