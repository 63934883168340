import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import Fab from '@material-ui/core/Fab';

import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import DeleteIcon from '@material-ui/icons/Delete';


const caseinfotypes = [{value: 0, label: 'תיאור פרטי המקרה בטופס'}, {value: 1, label: 'העלאת קובץ תיאור מוכן'}, {value: 2, label: 'שליחת פרטי המקרה מאוחר יותר באימייל או בווטסאפ '}, {value: 3, label: 'מעוניין/ת לתאר את פרטי המקרה בשיחת טלפון'}, ];

const useStyles = makeStyles((theme) => ({
	root: {
    flexGrow: 1,
    marginTop: theme.spacing(-2.5),
  },
	
	input: {
    display: 'none',
  },
	
	button: {
  },
	
	casefileheader: {
		marginBottom: theme.spacing(2),
	},
	
	casefiletext: {
		margin: theme.spacing(5, 0, 0),
	},
	
	deleteButton: {
		marginLeft: theme.spacing(-2),
	},
}));

export default function Case(props) {
  const classes = useStyles();
	const {check, valid, info, infoChanged} = props

  return (
	<div className={classes.root}>
		{false&&<TextField error={check&&!valid.info} select margin="dense" fullWidth value={info.caseinfotype} onChange={(e)=>infoChanged("caseinfotype", e.target.value)} helperText="ניתן לתאר את המקרה כאן, להעלות קובץ מוכן או לעדכן בהמשך">
			{caseinfotypes.map((option) => (
        <MenuItem key={option.value} value={option.value} dir="rtl">{option.label}</MenuItem>
      ))}
		</TextField>}
		
		{info.caseinfotype===0 ? 
		<div>
			<Box m={4} />
			<TextField margin="dense" error={check&&!valid.info} required fullWidth id="caseinfo" name="caseinfo" value={info.caseinfo} onChange={(e)=>infoChanged("caseinfo", e.target.value)}
			multiline rows={17} InputLabelProps={{shrink: true}} variant="outlined" />
		</div>
		:info.caseinfotype===1 ? 
		<div>
			<Box m={5} />
			{info.casefile === undefined ?
			<div>
				<Typography color="primary" className={classes.casefileheader}>העלה קובץ עם תיאור פרטי הארוע (וורד, pdf או כל קובץ טקסט אחר)</Typography>
				<Button variant="contained" component="label" className={classes.button} startIcon={<CloudUploadIcon />} >העלה קובץ<input type="file" value={info.casefile} onChange={(e)=>infoChanged("casefile", {name: e.target.files[0].name, obj: e.target.files[0]})} hidden /></Button>
				{false && <div style={{margin: "30px 0"}}><Fab color="secondary" aria-label="edit"><CloudUploadIcon /></Fab></div>}
			</div>
			:
			<React.Fragment>
				<Typography className={classes.casefiletext} variant="h6">קובץ תיאור המקרה:</Typography>
				<IconButton className={classes.deleteButton} onClick={(e)=>infoChanged("casefile", undefined)}><DeleteIcon /></IconButton> {info.casefile.name}
			</React.Fragment>}
		</div>
		:info.caseinfotype===2 ? 
		<React.Fragment>
			<Box m={3} />
			<Typography className={classes.casemailheader}>רוצים לספר לנו מה קרה מאוחר יותר? אין בעיה!</Typography>
			<Box m={1} />
			<Typography className={classes.casemailheader}>ניתן לשלוח את פרטי המקרה מאוחר יותר,</Typography>
			<Typography className={classes.casemailheader}>במייל: <strong>ofice@din-online.co.il</strong>,</Typography>
			<Typography className={classes.casemailheader}>או בווטסאפ, במספר: <strong>077-3142421</strong>.</Typography>
			<Box m={2} />
			<Typography className={classes.casemailheader}><strong>שימו לב: תחילת הטיפול בפנייה תתבצע לאחר השלמת הפרטים.</strong></Typography>
		</React.Fragment>
		:
		<React.Fragment>
			<Box m={3} />
			<Typography color="primary" className={classes.casemailheader}>רוצים להסביר לנו מה קרה בשיחת טלפון? אין בעיה!</Typography>
			<Box m={1} />
			<Typography className={classes.casemailheader}>ניתן לפנות אלינו בטלפון: <strong>077-3142421</strong>,</Typography>
			<Typography className={classes.casemailheader}>או להשאיר מספר ואנחנו נחזור אליכם:</Typography>
			<TextField margin="dense" fullWidth id="casephone" label="טלפון" name="casephone" autoComplete="phone" value={info.casephone} onChange={(e)=>infoChanged("casephone", e.target.value)} InputLabelProps={{shrink: true}} variant="outlined" />
			<Box m={2} />
			<Typography className={classes.casemailheader}><strong>שימו לב: תחילת הטיפול בפנייה תתבצע לאחר השלמת הפרטים.</strong></Typography>
		</React.Fragment>
		}

	</div>
		

  );
}