import React from 'react';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';

import Button from '@material-ui/core/Button';

export default function Protocol(props) {
	const {open, onClose} = props;
	
	const handleClose = () => {
    //setOpen(false);
		onClose();
  };
	
  return (
	  <Dialog open={open} dir="rtl">
			<DialogTitle>תקנון השירות</DialogTitle>
			<DialogContent>
				<DialogContentText component={'span'} variant="body2"><strong>האמור באתר small-claims.co.il אינו מהווה חוות-דעת משפטית והשימוש באתר אינו מהווה ייעוץ משפטי או תחליף לו. המידע באתר זה הינו כללי בלבד ואין להסתמך עליו. האתר הינו פרטי-עסקי והשימוש בו כפוף לתנאי השימוש באתר.</strong><br/><br/>

<Typography color="primary" variant="h6"><strong>תנאי השימוש באתר ומדיניות הפרטיות</strong><br/></Typography>
ברוכים הבאים לאתר small-claims.co.il ( “האתר”) המופעל על ידי עורכת הדין סיון ויסמן ("הנהלת האתר", "עורכי האתר", "מפעילי האתר", "בעלי האתר").
מטרתם של תנאי השימוש ומדיניות הפרטיות להלן ( “תנאי השימוש”) הינה להסדיר את השימוש של הגולשים – המשתמשים ( “אתה”, “המשתמש”, “לך”, “שלך”, וכיו”ב) באתר זה.
הנהלת האתר שומרת לעצמה הזכות לעדכן את תנאי השימוש המוצגים להלן מעת לעת וללא התראה או אזכור מיוחד בערוצי האתר השונים. תנאי השימוש אשר יחולו על משתמש יהיו תנאי השימוש אשר היו מפורסמים באתר בעת ביקורו האחרון באתר.<br/>
תנאי השימוש מנוסחים בלשון זכר לצרכי נוחות בלבד, אך הם מתייחסים לנשים ולגברים כאחד.<br/>
<strong>שים לב! השימוש באתר זה מהווה הסכמה מצידך לתנאי השימוש המפורטים להלן. אנא קרא מסמך זה בקפידה.</strong><br/><br/>

<Typography color="primary">1.  החלת תנאי השימוש</Typography>
אתר smaill-claims.co.il הינו אתר אינטרנט פרטי – עסקי המציג ומנגיש לכלל הציבור, מידע, כלים ושירותים לצורך הכנה וניהול של תביעות קטנות. האתר פועל במתכונת המעמידה שירותים לרשות הציבור ללא תשלום, ובצידה פעילות עסקית לכל דבר ועניין, שעיקרה מתן שירותים פרטניים תמורת תשלום עבור דמי טיפול.<br/>
כל התכנים, המידע והשירותים הנמצאים באתר או מסופקים באמצעותו ("שרותי האתר", "השירותים", "שרותי אתר small-claims.co.il") ניתנים לשימושך באדיבות הנהלת האתר אך ורק בכפוף לתנאי השימוש המופיעים באתר ("תנאי השימוש באתר"). הנהלת אתר שומרת על זכותה לשנות את תנאי השימוש באתר ואת תוכן האתר בכל עת ובהתאם לשיקול דעתה הבלעדי, ושימושך באתר יהיה כפוף לתנאי השימוש באתר כפי שיופיעו בו מעת לעת.
<Typography color="primary">2. הסתייגות מכל סמכות ותוקף</Typography>
התכנים ו/או המידע המוצגים באתר וכן השירותים השונים המוצעים על ידי האתר, ו/או כל חלק מהם, מוצגים ו/או נאמרים ו/או מועברים באופן תמציתי וכללי ועל כן יכולים שיהיו מעודכנים בצורה חלקית או לא מעודכנים כלל או שגויים או מטעים.<br/>
שרותי האתר אינם מהווים בשום אופן ייעוץ משפטי או תחליף לייעוץ משפטי, ובפרט אינם מהווים ייעוץ משפטי אישי או ייעוץ אחר או המלצה, או חוות דעת מכל סוג שהוא או טיפול משפטי מקצועי או אחר, המתחשב בנתונים ובצרכים הפרטיים המיוחדים של כל אדם, ואין בהם משום חיווי דעה אודות סיכויי ההצלחה בהליך כלשהו. מקום שבו ברצונך להנות מאחריות מקצועית, אנא היעזר בשירותיו של עורך דין המותאמים לצרכיך האישיים.<br/>
בשום אופן אין לראות בשרותי האתר ייעוץ משפטי. השימוש בשרותי האתר אינו יוצר יחסי עו"ד משתמש בין המשתמש להנהלת האתר, ואינו יוצר התחייבות כלשהי של האתר כלפי המשתמש בשרותיו.<br/>
התכנים באתר אינם באים להחליף את הוראות הדין, לרבות החקיקה והפסיקה. במקרה של אי התאמה עם הוראות הדין, הקובע הוא הנאמר בחוק.
<Typography color="primary">3. אחריות</Typography>
הסתמכות על המידע ו/או על התכנים ו/או השירותים המופיעים באתר, בין אם בחינם ובין אם בתשלום, הינה באחריות המשתמש בלבד. אין לעשות שימוש באתר ללא הפעלת שיקול דעת מקצועי ובלא בדיקה של המידע המופיע בו.<br/>
כל העושה שימוש במידע ובשירותים הניתנים באתר, עושה זאת על דעתו בלבד ועל אחריותו הבלעדית, ומסיר כל אחריות לעניין זה מהנהלת האתר או מי מטעמה. לפיכך, לא יוכל המשתמש להעלות טענות או תביעות או דרישות כלפי הנהלת אתר  או מי מטעמה, בגין כל אבדן, נזק או הפסד, ישיר או עקיף, כספי או אחר, שייגרם למשתמש או לצד ג' כלשהו כתוצאה משימוש או הסתמכות על המידע או התכנים או השירותים, הניתנים באתר או מסופקים על ידי מפעילי האתר<br/>
הינך מצהיר בזאת כי הינך מוותר על כל טענה ו/או תביעה ו/או דרישה כנגד מפעילי האתר, עובדיהם ו/או מי מטעמם, בכל הנוגע לשימוש שתעשה בתוכן ו/או בשירותים הניתנים באתר.<br/>
במידה ותפר את תנאי השימוש של האתר, תהיה הנהלת האתר זכאית לחשוף את שמך ואת הפרטים הידועים לה אודותיך בכל הליך משפטי, אף אם לא יינתן צו שיפוטי המורה על כך וככל שהחוק מתיר זאת. הנך מתחייב לשפות את הנהלת האתר, עובדיה, מנהליה ומי מטעמה בגין כל טענה, תביעה, נזק, הפסד, אבד רווח, תשלום או הוצאה שיגרמו לה (לרבות שכר טרחת עו”ד והוצאות משפט) עקב הפרה שלך את תנאי השימוש. כמו כן,  הנך  מתחייב לשפות את הנהלת האתר בגין כל טענה לנזק, תביעה או דרישה שתוגש נגדה, בקשר לתוכן שהועלה על ידיך לאתר והודעות שנשלחו על ידך במסגרתו.
<Typography color="primary">4. הגבלת רשות השימוש</Typography>
מי שרשאי להשתמש באתר ו/או לבצע רכישות באתר חייב לענות על כל תנאי הכשירות הבאים:<br/>
•	אזרח ישראלי בעל תעודת זהות ישראלית בתוקף המתגורר בישראל ובעל כתובת מגורים עדכנית בישראל.<br/>
•	בן 18 שנים לפחות, כשיר משפטית על פי דין ושלא מונה לו אפוטרופוס;<br/>
•	בעל כתובת אימייל ומספר נייד פעילים;<br/>
•	בעל כתובת פיזית עדכנית בישראל לקבלת הודעות.<br/>
בלא קבלת רשות מפורשת מראש ובכתב של הנהלת האתר אינך רשאי לבצע כל פעולה מכל סוג שהוא, בין אם נעשית בתמורה או שלא בתמורה, או לעשות כל שימוש מסחרי מכל סוג שהוא באתר ובתכנים ובכל חלק מהם או בכל מידע או תוכן אחר שהתקבל באמצעותם. אינך רשאי להשתמש בתוכנות גילוי וניטור או בכל יישום מחשב או כל אמצעי אחר לשם חיפוש, סריקה, העתקה או אחזור אוטומטי של תכנים מתוך האתר, או כדי להתחקות אחר מבנה האתר, דרך פעולתו, מידע על השימוש בו או על משתמשיו. אינך רשאי לעשות באתר, בתכנים ובתוכנה שום שימוש שלא הורשה במפורש בתנאי השימוש ושום שימוש שיש בו משום הפרת הדין הישראלי.
<Typography color="primary">5. קישורים באתר</Typography>
באתר מופיעים קישורים (links) לתכנים של צדדים שלישיים ("תכנים של צדדים שלישיים") שנועדו לנוחות המשתמש בלבד וההתייחסות והשימוש בהם נתונים לשיקול דעתו הבלעדי של המשתמש. אין לראות בקישורים הקיימים באתר המלצה מוסמכת לשימוש בהם. הנהלת האתר אינה אחראית לתכנים של צדדים שלישיים ואינה אחראית לשום דבר הקשור לתכנים של צדדים שלישיים.<br/>
הנהלת אתר או מי מטעמה, לא יישאו באחריות לכל נזק ישיר או עקיף, כספי או אחר, שייגרם לך או לאחר כתוצאה משימוש או הסתמכות על תכנים או שירותים של צדדים שלישיים.
<Typography color="primary">6. קניין רוחני</Typography>
האתר כמו גם כל המידע שבו לרבות עיצוב האתר, קוד האתר, קבצי מדיה לרבות המסמכים המופקים ממנו על כל צורותיהם, תמונות, טקסטים, קבצים המוצעים להורדה וכל חומר אחר אשר מוצג באתר שייכים במלואם להנהלת האתר ומהווים קניין רוחני בלעדי של הנהלת האתר ואין לעשות בהם שימוש ללא אישור כתוב מראש מאת הנהלת האתר. בנוסף אין להפיץ, להעתיק, לשכפל, לפרסם, לחקות או לעבד את המסמכים המופקים באמצעות האתר, פיסות קוד, גרפיקות, סרטונים, סימנים מסחריים או כל מדיה ותוכן אחר ללא קבלת אישור כתוב מראש. על אף האמור עריכת מסמך שהופק במסגרת האתר ע”י בעל המנוי (ועל ידו בלבד) לשימוש פרטי ולצורך שלשמו הופק המסמך מלכתחילה הינו שימוש מותר.<br/>
קבלת מסמך משפטי או שאינו משפטי שהופק במסגרת רכישת שירות באתר מעניקה לבעל המנוי זיכיון שימוש בתוכן המסמך כפי שהופק, לצרכיו הפרטיים בלבד, וכל שימוש מסחרי במסמך ו/או שימוש על ידי אחר מוחרג במסגרת הרישיון. קבלת מסמך כאמור אינה מעניקה לבעל המנוי כל בעלות ו/או זכויות יוצרים במסמך ו/או בכל חלק ממנו ו/או מתוכנו.
<Typography color="primary">7. איסור שימוש בלתי חוקי, מזיק או מפריע</Typography>
אינך רשאי להשתמש באתר ובתכניו למטרה בלתי חוקית כלשהי. אינך רשאי להשתמש באתר ובתכניו באופן שיש בו אפשרות להזיק, לחבל, לשתק, לגרום עומס יתר או לפגוע בכל דרך שהיא באתר, ברשתות ובציוד תקשורת, או להפריע לשימוש של משתמש אחר באתר או ברשת או לזולת. אינך רשאי לעשות כל פעולה שיש בה כדי להשיג או לנסות להשיג מידע או תכנים כלשהם באמצעים שלא הועמדו לרשותך במפורש על פי תנאי השימוש.
<Typography color="primary">8. מסירת פרטים והגשת פנייה</Typography>
לצורך קבלת שירות באתר תידרש לספק פרטים אישיים, לרבות שם פרטי ומשפחה, מספר טלפון, כתובת, כתובת דואר אלקטרוני, וכן מידע אודות נסיבות התביעה או כל פרט שהנהלת האתר תקבע שנחוץ לצורך יצירת קשר או מתן שירות. בפנייתך לקבלת סיוע באמצעות הטופס המקוון שבאתר, הינך נותן הסכמתך שפרטי פנייתך יועברו להנהלת האתר וישמשו ליצירת קשר ישיר עמך. לפי חוק, אינך חייב למסור פרטים אלה ומסירתם תלויה ברצונך בלבד. עם זאת, אם לא מסרת את כל הפרטים המבוקשים לא תוכל לקבל מענה הולם ומספק מהנהלת האתר. במתן הפרטים הינך מתחייב כי פרטי ההרשמה הם נכונים ומדויקים וכי מסירת פרטים כוזבים ביודעין עלולה לעלות לכדי עבירה פלילית.<br/>
הנהלת האתר אינה מחויבת בשום מקרה לענות ו/או להתייחס לשאלות ו/או לפניות שנשלחו לאתר, ושמורה לה הזכות להתעלם מפניתך בהתאם לשיקול דעתה הבלעדי ומבלי שתידרש לספק הסברים לכך.
<Typography color="primary">9. פרטיות</Typography>
הפרטים האישיים שתמסור בעת פנייתך ישמרו במאגרי המידע של האתר, למעט פרטי אמצעי התשלום אשר אינם נשמרים במערכות האתר, בשום שלב.<br/>
הנהלת האתר יעשו תעשה כמיטב יכולתה באמצעים העומדים לרשותה כדי להגן על פרטי המשמש ופרטיותו ולשמור את המידע שימסור בהתאם למדיניות הפרטיות המובאת בזאת. ואולם, חשוב להבהיר כי מאחר ומדובר בביצוע פעולות בסביבה מקוונת, אין באפשרות הנהלת האתר להבטיח חסינות מוחלטת מפני חדירות למחשבים או חשיפת המידע האגור בו עקב תקלה או טעות אנוש.<br/>
בנסיבות אלה, לא ניתן להבטיח הגנה מלאה על פרטים אלו, ולפיכך, בהסתמך לתנאי השימוש באתר, הינך פוטר בזה את הנהלת האתר ומי מטעמה מכל אחריות לכל פגיעה או נזק, ישיר או עקיף, כספי או אחר, שיגרם לך כתוצאה מדליפת פרטים אלה.
<Typography color="primary">10. מדיניות שירות</Typography>
<strong>10.א.</strong> הזמנת שירות תתבצע על ידי המשתמש באמצעות מילוי טופס ההזמנה המופיע באתר. יובהר כי השלמת מילוי הטופס והשלמת התשלום עבור השירות מהווים תנאי מוקדם לביצוע ההזמנה.<br/>
<strong>10.ב.</strong> על מנת להבטיח את ביצוע ההזמנה ביעילות וללא תקלות, יש להקפיד על מסירת כל הפרטים הנדרשים באתר במדויק. אם בעת ביצוע ההזמנה יימסרו פרטים שגויים, הנהלת האתר לא תוכל להבטיח שהשירות יושלם ללא תקלות.<br/>
<strong>10.ג.</strong> פרטי ההזמנה כפי שהזין המשתמש בטופס ההזמנה וקליטת העסקה במחשבי הנהלת האתר יהוו ראייה חלוטה וסופית להזמנת השירות<br/>
<strong>10.ד.</strong> לאחר הזמנת השירות תישלח למשתמש הודעת דואר אלקטרוני המאשרת את קליטת פרטי ההזמנה. אישור זה עדיין אינו מאשר כי הנהלת האתר מקבלת על עצמה את אספקת את השירות האמור, אלא הוא מעיד אך ורק כי פרטי ההזמנה נקלטו אצל הנהלת האתר וכי הנהלת האתר מתחילה לבחון את הטיפול בהזמנה. הנהלת האתר רשאית לסרב להשלים את הטיפול בהזמנה. במידה והנהלת האתר תחליט לסרב להשלים הטיפול בהזמנה, היא תודיע על כך למשתמש בהקדם האפשרי והמשתמש יקבל החזר התשלום במלואו.<br/>
<strong>10.ה.</strong> אישור רשמי על הגשת כתב תביעה יישלח אל המשתמש בדוא"ל או בהודעת וואטסאפ. הזמן הצפוי לשליחת האישור אל המשתמש הינו 3 ימי עסקים ממועד ביצוע ההזמנה. במידה ולא התקבל האישור אצל המשתמש בתוך פרק זמן זה, עליו לפנות להנהלת האתר לצורך בירור.<br/>
<strong>10.ו.</strong> יודגש כי הנהלת האתר תהיה רשאית לבטל הזמנה של משתמש מכל סיבה שהיא ועל-פי שיקול דעתה הבלעדי ולמשתמש לא תהא כל טענה בקשר לכך, בכפוף להשבת הסכום ששולם להנהלת האתר על ידי המשתמש. בכל מקרה, הנהלת האתר לא תהיה אחראית ולא תישא באחריות לכל נזק ישיר, עקיף, תוצאתי או מיוחד שנגרם למשתמש או לצד שלישי לרבות, אך לא רק, נזק בגין אפשרות לרכישת השירות אצל צד שלישי במחיר שונה מהמחיר באתר. לעניין זה, הזכות לביטול עסקה נתון להנהלת האתר לרבות, אך לא רק, בכל אחד מהמקרים המפורטים להלן:<br/>
<strong>10.ז.1.</strong> במקרה בו לא אושרה העסקה ע״י חברת האשראי או כל שירות תשלום אחר המוצע באתר ונבחר ע"י המשתמש.<br/>
<strong>10.ז.2.</strong> במצבים בהם הנהלת האתר מנועה מלספק את השירות מכל סיבה שהיא.<br/>
<strong>10.ז.3.</strong> אם הנהלת האתר חושדת כי המשתמש מסר פרטים שגויים, במתכוון או שלא במתכוון.<br/>
<strong>10.ז.4.</strong> אם לא נקלטו במערכת פרטיו המלאים של המשתמש ושל הזמנתו.<br/>
<strong>10.ז.5.</strong> אם התגלה כי קיים מכשול ביכולת לספק את השירות.<br/>
<strong>10.ז.6.</strong> אם בוצעו מעשה או מחדל, הפוגעים או עלולים לפגוע באתר או בהנהלת האתר, או בצדדים שלישיים כלשהם.<br/>
<strong>10.ז.7.</strong> אם שירותי האתר שימשו לביצוע מעשה הנחזה כבלתי חוקי על-פי דיני מדינת ישראל או כדי לאפשר, להקל, לסייע או לעודד ביצועו של מעשה כזה.<br/>
<strong>10.ז.8.</strong> אם הופר תנאי בהסכם זה, או כל תנאי אחר שהוצג על ידי הנהלת האתר.
<strong>10.ז.10.</strong> אם לחובת המשתמש עומד חוב כספי להנהלת האתר או לגורמים הקשורים עמה והחוב לא נפרע, למרות שחלף המועד לתשלומו.<br/>
<strong>10.ז.10.</strong> אם הנהלת האתר חושדת כי בכוונת המשתמש למכור את השירות שנרכש ממנה לצד ג'. בהקשר זה המשתמש מאשר ומתחייב כי לא ימכור שירות שרכש באתר לצד ג' כלשהו.<br/>
<strong>10.ז.11.</strong> אם הנהלת האתר לא הצליחה ליצור קשר עם המשתמש.<br/>
<strong>10.ז.12.</strong> בכל מקרה אשר בו, מחמת "כח עליון", אין באפשרות להנהלת האתר לבצע ניהול תקין של האתר, לספק למשתמש את השירות ו/או לעמוד בהתחייבות אחרת שלה. בסעיף זה "כוח עליון" משמעו: לרבות תקלות מחשב, תקלות במערכת טלפונים או תקלות במערכות תקשורת אחרות, חבלה כלשהי ואירוע בטחוני.<br/>
<strong>10.ח.</strong> לעניין התנאים במסמך זה, ימי עסקים לא יכללו ימים בהם לא מתקיימת פעילות סדירה מלאה באחר או יותר מהגורמים הקשורים לפעילות האתר, כגון: ימי פגרת בית משפט,  ימי שביתה בדואר ישראל, וכדומה.<br/>
<strong>10.ט.</strong> התשלום באתר הינו באמצעות סליקה מקוונת של כרטיס אשראי. אנו מכבדים כרטיסי אשראי של המותגים הבאים: ויזה, מאסטרקארד, ישראכרט, לאומי קארד, דיינרס.<br/><br/>
<strong>בשימושך באתר אתה מסכים באופן בלתי חוזר לתנאי שימוש אלו במלואם וללא תנאי או סייג.</strong>

					
					
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button color="primary" onClick={handleClose}>סגור</Button>
			</DialogActions>
		</Dialog>
  );
}

