import React, {useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import CanvasDraw from "react-canvas-draw";

import Protocol from './Protocol';

const useStyles = makeStyles((theme) => ({
	root: {
    flexGrow: 1,
    marginTop: theme.spacing(-1),
  },
	
	check: {
		margin: theme.spacing(-1, 0, 0, -1),
	},
	
	sign: {
		maxWidth: "400px",
		margin: "auto",
	},
	canvas: {
		borderStyle: "solid",
		borderWidth: 1,
	},
}));

export default function Sign(props) {
  const classes = useStyles();
	const {check, valid, info, infoChanged} = props
	const [protocolMode, setProtocolMode] = useState(false);
	let saveableCanvas;
	
	const handleCanvas = canvasDraw => {
		if (canvasDraw) saveableCanvas = canvasDraw;
		else info.signature = saveableCanvas.getSaveData();
	};

  return (
	<div className={classes.root}>
		<FormControlLabel className={classes.check} control={<Checkbox name="a1" color="primary" checked={info.a1} onChange={(e)=>infoChanged(e.target.name, e.target.checked)} />}
        label={<Typography color={valid.a1||!check?"inherit":"secondary"} variant="body2">התובע מסכים <Link href="#" onClick={()=>setProtocolMode(true)}>לתקנון שירות</Link> הגשת התביעה הקטנה</Typography>} />
		<Protocol open={protocolMode} onClose={()=>setProtocolMode(false)} />
		<FormControlLabel className={classes.check} control={<Checkbox name="a2" color="primary" checked={info.a2} onChange={(e)=>infoChanged(e.target.name, e.target.checked)} />}
        label={<Typography color={valid.a2||!check?"inherit":"secondary"} variant="body2">התובע הינו אדם פרטי (אינו חברה)</Typography>} />
		<FormControlLabel className={classes.check} control={<Checkbox name="a3" color="primary" checked={info.a3} onChange={(e)=>infoChanged(e.target.name, e.target.checked)} />}
        label={<Typography color={valid.a3||!check?"inherit":"secondary"} variant="body2">התובע לא הגיש מעל 5 תביעות קטנות לביה"מ במהלך השנה</Typography>} />
		<Box mt={3} />
		<div className={classes.sign}>
			<Typography><strong>חתימה: </strong>[<Link style={{cursor: 'pointer'}} onClick={()=>saveableCanvas.clear()}>נקה</Link>]</Typography>
			<CanvasDraw className={classes.canvas} ref={canvasDraw => handleCanvas(canvasDraw)} saveData={info.signature} immediateLoading={true} lazyRadius={0} brushRadius={2} canvasWidth="100%" canvasHeight="200px" brushColor="#444" catenaryColor="#FFF" hideGrid />
		</div>

	</div>
  );
}