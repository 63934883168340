import React, {useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

import axios from 'axios';
import { useSnackbar } from 'notistack';

const useStyles = makeStyles((theme) => ({
	backdrop: {
    zIndex: 100,
    color: '#fff',
  },
	
	button: {
		margin: theme.spacing(-3),
	},
	
}));

export default function Contact(props) {
  const classes = useStyles();
	const {open, onClose} = props;
	const [check, setCheck] = useState(false);
	const [sendMode, setSendMode] = useState(false);
	const [msg, setMsg] = useState({sender:"", phone:"", email:"", info:""});
	const { enqueueSnackbar } = useSnackbar();
	
	const send = (abort) => {
		if (!abort) {
			setCheck(true);
			if (msg.sender && msg.email && msg.info) {
				onClose();
				setSendMode(true);
				const fd = new FormData();
				fd.append('msg', JSON.stringify(msg));
				axios.post('https://srv.small-claims.co.il/contact.php', fd)
				.then(res => {
					enqueueSnackbar("!ההודעה נשלחה בהצלחה", { variant: 'success',});
					
					setSendMode(false);
				});
				
			}
		} else onClose();
  };
	
  return (
		<React.Fragment>
			<Backdrop className={classes.backdrop} open={sendMode}><CircularProgress color="inherit" /></Backdrop>
			<Dialog open={open} dir="rtl">
				<DialogTitle>צור קשר</DialogTitle>
				<DialogContent>
					<DialogContentText>
						טופס זה נועד ליצירת קשר בעניינים שונים כגון: אופן מילוי כתב התביעה, בירור מצב הטיפול בפניה, נושאים טכניים וכדומה.
					</DialogContentText>
					<TextField margin="dense" id="name" value={msg.sender} required error={check && !msg.sender} label="שם" autoComplete="name" fullWidth onChange={(e)=>setMsg({...msg, sender: e.target.value})} />
					<TextField margin="dense" id="phone" value={msg.phone} label="מספר טלפון" autoComplete="phone"  type="tel" fullWidth onChange={(e)=>setMsg({...msg, phone: e.target.value})} />
					<TextField margin="dense" id="email" value={msg.email} required error={check && !msg.email} label="כתובת אימייל" autoComplete="email" fullWidth onChange={(e)=>setMsg({...msg, email: e.target.value})} />
					<TextField margin="dense" id="info" value={msg.info} required error={check && !msg.info} label="הודעה" fullWidth multiline rows={3} onChange={(e)=>setMsg({...msg, info: e.target.value})} />
				</DialogContent>
				<DialogActions>
					<Button className={classes.button} color="primary" onClick={()=>send(true)}>בטל</Button>
					<Button color="primary" onClick={()=>send(false)}>שלח</Button>
				</DialogActions>
			</Dialog>
		</React.Fragment>
  );
}

