import React, {useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';

import JSEncrypt from 'jsencrypt';
import MaskedInput from 'react-text-mask';
import PropTypes from 'prop-types';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

import PaymentIcon from '@material-ui/icons/Payment';
import PersonIcon from '@material-ui/icons/Person';

const useStyles = makeStyles((theme) => ({
	payment: {
		maxWidth: theme.spacing(45),
    margin: theme.spacing(5, "auto"),
	},
	
	paymentFotter: {
		display: "flex",
		justifyContent: "center",
	},
	
	button: {
		borderRadius: theme.spacing(10),
		height: theme.spacing(6),
		fontSize: "18px",
		margin: "auto",
	},
}));

function CreditNumberMask(props) {
  const { inputRef, ...other } = props;
  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[/\d/, /\d/, /\d/,/\d/, ' ', /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/,]}
      placeholderChar={'\u2000'}
    />
  );
}
CreditNumberMask.propTypes = {
  inputRef: PropTypes.func.isRequired,
};

function ExpirerdMask(props) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[/\d/, /\d/, '/', /\d/, /\d/,]}
      placeholderChar={'\u2000'}
    />
  );
}
ExpirerdMask.propTypes = {
  inputRef: PropTypes.func.isRequired,
};

export default function Payment(props) {
  const classes = useStyles();
	const {fee, trajid, trajectory, paymentFilled} = props
  const [cardInfo, setCardInfo] = useState({creditNumber: '', iid: '', expired: '', cvv: '',});
	const [check, setCheck] = useState(false);
	const [ended, setEnded] = useState(false);
	const isError = {creditNumber: ()=>cardInfo.creditNumber===""||cardInfo.creditNumber.includes('\u2000'),
									 iid: ()=>cardInfo.iid.length!==9,
									 expired: ()=>cardInfo.expired===""||cardInfo.expired.includes('\u2000'),
									 cvv: ()=>cardInfo.cvv.length!==3,};
	const pubkey = "MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAsrK1kK7t/zijtkHIlxoNbtpxViLdIayQiUSfYHeJzQBrwr+O2yugZLZOaBMS1A4ZCZhq/qxFGMYjW5qB1gw2tb0xdvmSv1ZWDEjAP2P93FcaJPGF2PUuD51snRid+BOHrSbqgV5gLzVJ4Oa6eDnquY4QvZXzn+vJpYi6o8/iqsDo1Wo3ppsVgBP02gLrPJRs2FqhuUhODYkrW0WwnJsOLoIGoMj8qHweqp4xHAabWUdU3hIpPM3lXubeDd/1S/pF9bNdguukuyeKzWFU9ISqaMF35jEntX/YwTYX2KhG+gSIEnO4L5uul4+nh4XGHCbSudqRegQCatKke9uirB1UeQIDAQAB"

									 
  const handleChange = (e) => {
		if (e.target.name==="cvv" && e.target.value.length>3) return;
		if (e.target.name==="iid" && e.target.value.length>9) return;
    setCardInfo({...cardInfo, [e.target.name]: e.target.value,});
	};
	
	const buttonClicked = () => {
		setCheck(true);
		if (!isError.creditNumber()&&!isError.iid()&&!isError.expired()&&!isError.cvv()) {
			setEnded(true);
			
			var card = cardInfo.creditNumber+cardInfo.iid+cardInfo.expired+cardInfo.cvv;
			card = card.replace(/\s+/g, '');
			var encrypt = new JSEncrypt();
			encrypt.setPublicKey(pubkey);
			card = encrypt.encrypt(card);
			paymentFilled(card);
		}
	};
		
  return (
	<div className={classes.payment}>
		{!ended?
		<React.Fragment>
			{false&&<div>
				<Typography color="primary">סיכום תשלומים:</Typography>
				<strong>
					<Grid container spacing={0} alignItems="flex-end"><Grid item xs><Typography>שרות {trajectory.title}</Typography></Grid><Grid item>{trajectory.price}</Grid></Grid>
					<Grid container spacing={0} alignItems="flex-end"><Grid item xs><Typography>אגרת בית משפט</Typography></Grid><Grid item>{fee} ש"ח</Grid></Grid>
				</strong>
			</div>}
			<Grid container spacing={1} alignItems="flex-end">
				<Grid item xs dir="ltr"><TextField error={check&&isError.creditNumber()} margin="dense" fullWidth id="creditNumber" label="מספר כרטיס" name="creditNumber" value={cardInfo.creditNumber} onChange={handleChange} InputProps={{inputComponent: CreditNumberMask,}} /></Grid>
				<Grid item ><PaymentIcon /></Grid>
			</Grid>
			<Grid container spacing={1} alignItems="flex-end">
				<Grid item xs dir="ltr"><TextField error={check&&isError.iid()} margin="dense" fullWidth id="iid" label="תעודת זהות של בעל הכרטיס" name="iid" type="number" value={cardInfo.iid} onChange={handleChange} /></Grid>
				<Grid item><PersonIcon /></Grid>
			</Grid>
			<Grid container spacing={2} alignItems="flex-start">
				<Grid item xs><TextField  dir="ltr" error={check&&isError.expired()} margin="dense" id="expired" label="תאריך תפוגה" placeholder="MM/YY" name="expired" value={cardInfo.expired} onChange={handleChange} InputProps={{inputComponent: ExpirerdMask,}} InputLabelProps={{shrink: true}} />		
				</Grid>
				<Grid item xs>
					<TextField  dir="rtl" error={check&&isError.cvv()} margin="dense" type="number" id="cvv" label="קוד אבטחת הכרטיס" placeholder="3 ספרות בגב הכרטיס" name="cvv" value={cardInfo.cvv} onChange={handleChange} InputLabelProps={{shrink: true}} />		
				</Grid>
			</Grid>
			<Box mt={10} />
			<div className={classes.paymentFotter}><Button className={classes.button} fullWidth variant="contained" color="primary" onClick={buttonClicked}>לתשלום</Button></div>
			<Box mt={8} />
			<div className={classes.paymentFotter}><img className={classes.paymentLogos} src="credit-companies.png" alt="" /></div>
			<Box mt={0} />
			<div className={classes.paymentFotter}><strong>התשלום מאובטח ופרטי האשראי אינם נשמרים במערכת</strong></div>
		</React.Fragment>
		:
		<React.Fragment>
			<Box mt={7} />
			<Typography variant="h5">כתב התביעה הדיגיטלי שהזנת נשלח בהצלחה לשרתי המערכת.</Typography>
			<Box mt={4} />
			{trajid===0?
			<React.Fragment>
				<Typography align="justify">זמן העיבוד הממוצע של התביעה הוא כחמישה ימי עסקים.<br />
			בסיום התהליך נשלח אליך העתק של כתב התביעה, קבלה על תשלום האגרה, אסמכתא לפתיחת תיק, מועד דיון (לכשיקבע על ידי בית המשפט) ומדריך הסבר להמשך מעקב אחר שלבי התביעה באתר בית המשפט.</Typography>
				<Box mt={2} />
				<Typography align="justify"><strong>שים לב שבקשת התביעה נפתחת ללא בקשת אישור נוספת. אם ברצונך לעכב מסיבה כולשהי את הגשת התביעה, אנא שלח הודעת ווטסאפ למספר 055-3057175 במהלך 24 השעות הקרובות.</strong></Typography>
				<Box mt={4} />
				<Typography variant="h6">אנו מאחלים לך הצלחה בהמשך!</Typography>
			</React.Fragment>
			:
			<React.Fragment>
				<Typography align="justify">אנו ניצור קשר לגבי המשך התהליך, לאחר שנעבור על טיוטת כתב התביעה.</Typography> 
				<Box mt={2} />
				<Typography><strong>ניתן לפנות אלינו בכל שאלה בטלפון 055-3057175 או במייל: support@small-claims.co.il</strong></Typography>
			</React.Fragment>
			}
		</React.Fragment>
		}
	</div>
  );
}