import React, {useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import Contact from './Contact';

const useStyles = makeStyles((theme) => ({
	trajectory: {
		padding: theme.spacing(0,1),
	},
	
	trajectoryTitle: {
		margin: theme.spacing(5, 0, 3, 0),
	},
	accordionSummary: {
		flexGrow: 1,
	},
	
	heading: {
    fontSize: theme.typography.pxToRem(20),
  },
	
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
	
	 details: {
    flexGrow: 1,
		marginTop: theme.spacing(-2.5),
  },
	
	chooseButton: {
		margin: theme.spacing(3,2),
	},
	
	button: {
		borderRadius: theme.spacing(10),
		height: theme.spacing(6),
		fontSize: "18px",
		margin: "auto",
	},
	
	
}));

export default function TrajChoose(props) {
  const classes = useStyles();
	const {fee, trajectories, onChoose} = props;
	const [expanded, setExpanded] = useState(false);
	const [contactForm, setContactForm] = useState(false);
	

  const handleExpanded = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
	<div className={classes.trajectory}>
		<Typography className={classes.trajectoryTitle} variant="h6">בחר במסלול הרצוי:</Typography>
		<Accordion expanded={expanded === 'panel1'} onChange={handleExpanded('panel1')}>
			<AccordionSummary className={classes.accordionSummary} expandIcon={<ExpandMoreIcon />} >
				<Grid>
					<Grid item><Typography className={classes.heading}>{trajectories[0].title}</Typography></Grid>
					<Grid item><Typography className={classes.secondaryHeading}>דמי שירות:  {trajectories[0].price} (כולל מע"מ), בתוספת {fee} ש"ח אגרת בית משפט</Typography></Grid>
				</Grid>
			</AccordionSummary>
				<AccordionDetails>
				<Grid className={classes.details}>
					<Grid item><Box mt={1} /><Typography color="primary">מה כולל המסלול?</Typography></Grid>
					<Grid item><Typography align="justify" variant="body2">במסלול הבסיסי אתם ממלאים את הטופס המקוון באתר באופן עצמאי ואנו מבצעים בקרה על נכונות המילוי (לא בקרה משפטית), עורכים את כתב התביעה ומגישים אותו עבורכם לבית המשפט (עותק ערוך של כתב התביעה ישלח בדוא"ל). לאחר מספר ימים נשלח לכם את מספר התיק ואת מועד הדיון וכן הסבר מפורט כיצד ניתן  להתחבר לתיק באמצעות מערכת "נט המשפט" לצורך מעקב אחר התקדמות ההליכים בתיק והגשת מסמכים במידת הצורך.</Typography></Grid>
					<Grid item><Box mt={1} /><Typography color="primary">למי מיועד המסלול?</Typography></Grid>
					<Grid item><Typography align="justify" variant="body2">המסלול הבסיסי מתאים לכל מי שמעוניין להגיש בעצמו תביעה קטנה, באופן פשוט וחסכוני, ומומלץ לאנשים בעלי ניסיון קודם בהגשת תביעות קטנות או כאשר עובדות המקרה ברורות ולא נדרש הסבר משפטי.<br/>ניתן לפנות באמצעות <Link href="#" onClick={()=>setContactForm(true)}>צור קשר</Link> בשאלות לגבי אופן מילוי הנתונים באתר בלבד, אך לא יינתן ייעוץ משפטי.<br/><br/>
						<strong>לתשומת ליבכם, שירות זה <u>אינו</u> משפטי! לאחר עריכת כתב התביעה נשלח אותו באימייל לאישורכם. רק לאחר האישור, הוא יוגש לבית המשפט.</strong></Typography></Grid>
					<Grid item><div className={classes.chooseButton}><Button className={classes.button} fullWidth variant="contained" color="primary" onClick={()=>{onChoose(0)}}>בחר ב{trajectories[0].title}</Button></div></Grid>
				</Grid>
				<Contact open={contactForm} onClose={()=>setContactForm(false)} />
			</AccordionDetails>
		</Accordion>
		<Accordion expanded={expanded === 'panel2'} onChange={handleExpanded('panel2')}>
			<AccordionSummary className={classes.accordionSummary} expandIcon={<ExpandMoreIcon />} >
				<Grid>
					<Grid item><Typography className={classes.heading}>{trajectories[1].title}</Typography></Grid>
					<Grid item><Typography className={classes.secondaryHeading}>דמי שירות:  {trajectories[1].price} (כולל מע"מ), בתוספת {fee} ש"ח אגרת בית משפט</Typography></Grid>
				</Grid>
			</AccordionSummary>
      <AccordionDetails>
				<Grid className={classes.details}>
					<Grid item><Box mt={1} /><Typography color="primary">מה כולל המסלול?</Typography></Grid>
					<Grid item><Typography align="justify" variant="body2">מסלול הייעוץ המשפטי כולל את כל שלבי המסלול הבסיסי, ובנוסף - עורך דין מנוסה בהליכי תביעות קטנות, יקרא את כתב התביעה ויצור קשר עם בעל הדין כדי לייעץ ולהדריך, הן לגבי ההליך והן לגבי ההיבטים המשפטיים הנוגעים לעובדות התביעה (לשון ופרשנויות החוק, חיזוק טענות, מיקוד הנימוקים וכו').</Typography></Grid>
					<Grid item><Box mt={1} /><Typography color="primary">למי מיועד המסלול?</Typography></Grid>
					<Grid item><Typography align="justify" variant="body2">מסלול הייעוץ מתאים עבור מי שמעוניין לנסח את כתב התביעה בעצמו, אך זקוק לייעוץ והכוונה בהיבטים הטכניים של הליך הגשת התביעה ובהיבטים משפטיים של טיעונים תומכים ומחזקים לנימוקי התביעה.<br/><br/>
					<strong>בסיום ההתייעצות, יוכל בעל הדין לתקן את כתב התביעה באופן עצמאי לאור המלצות עורך הדין ואף לקיים התייעצויות נוספות, עד לשביעות רצונו.</strong></Typography></Grid>
					<Box mt={1} />
					<Grid item><Typography align="justify" variant="body2"><strong>השירות במסגרת מסלול הייעוץ המשפטי ניתן ע"י משרד עו"ד ויסמן סלע, באמצעות <Link href="https://www.din-online.co.il">מרכז "דין אונליין" לשירותים משפטיים מקוונים</Link>.</strong></Typography></Grid>
					<Grid item><div className={classes.chooseButton}><Button className={classes.button} fullWidth variant="contained" color="primary" onClick={()=>{onChoose(1)}}>בחר ב{trajectories[1].title}</Button></div></Grid>
				</Grid>
			</AccordionDetails>
    </Accordion>
		<Accordion expanded={expanded === 'panel3'} onChange={handleExpanded('panel3')}>
			<AccordionSummary expandIcon={<ExpandMoreIcon />} >
				<Grid>
					<Grid item><Typography className={classes.heading}>{trajectories[2].title}</Typography></Grid>
					<Grid item><Typography className={classes.secondaryHeading}>דמי שירות:  {trajectories[2].price} (כולל מע"מ), בתוספת {fee} ש"ח אגרת בית משפט</Typography></Grid>
				</Grid>
			</AccordionSummary>
      <AccordionDetails>
				<Grid  className={classes.details}>
					<Grid item><Box mt={1} /><Typography><strong>הכנה והגשה של כתב תביעה קטנה על ידי עו"ד</strong></Typography></Grid>
					<Grid item><Typography align="justify" variant="body2">בכתב תביעה המוגש לבית המשפט נדרש התובע להציג את טענותיו מהיבטים עובדתיים ומשפטיים. כתב תביעה שערוך באופן מקצועי ומוגש לבית המשפט כדין, מגביר באופן משמעותי את סיכוייו של התובע לזכות בתביעה.</Typography></Grid>
					<Grid item><Box mt={1} /><Typography color="primary">מה כולל המסלול?</Typography></Grid>
					<Grid item><Typography align="justify" variant="body2">במסלול המקיף עורך דין מוסמך ילווה אתכם וייעץ בכל שלבי התביעה, החל מניסוח ועריכת כתב התביעה, דרך הגשתו לבית המשפט לתביעות קטנות וקביעת מועד דיון ועד להכנה טלפונית בסמוך למועד הדיון.</Typography></Grid>
					<Grid item><Box mt={1} /><Typography color="primary">למי מיועד המסלול?</Typography></Grid>
					<Grid item><Typography align="justify" variant="body2">לכל תובע שמעוניין בהגשת כתב תביעה מנומק ומנוסח באופן מקצועי וזקוק לליווי משפטי על ידי עורך דין מיומן.</Typography></Grid>
					<Grid item><Typography align="justify" variant="body2"><br /><strong>השירות במסגרת המסלול המקיף ניתן ע"י משרד עו"ד ויסמן סלע, באמצעות <Link href="https://www.din-online.co.il">מרכז "דין אונליין" לשירותים משפטיים מקוונים</Link></strong>.</Typography></Grid>
					<Grid item><div className={classes.chooseButton}><Button className={classes.button} fullWidth variant="contained" color="primary" onClick={()=>{onChoose(2)}}>בחר ב{trajectories[2].title}</Button></div></Grid>
				</Grid>
			</AccordionDetails>
		</Accordion>
	</div>
  );
}