import React, {useState, useRef} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Attachment from './Attachment';

import Link from '@material-ui/core/Link';
import IconButton from '@material-ui/core/IconButton';
import Fab from '@material-ui/core/Fab';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';

const useStyles = makeStyles((theme) => ({
	root: {
   flexGrow: 1,
    margin: theme.spacing(-1, 0, 0, 0),
		width: '100%',
  },

	deleteButton: {
		margin: theme.spacing(-1, 0, 0, -2),
	},
	
	addButton: {
		bottom: "0px",
		margin: theme.spacing(0),
	},
	
}));

export default function Attachments(props) {
  const classes = useStyles();
	const attachmentsRef = useRef();
	
	const {info, infoChanged} = props;
	const [attachForm, setAttachForm] = useState(false);
	const [editAttachment, setEditAttachment] = useState(undefined);

	const openForm = (editAttachment) => {
		setEditAttachment(editAttachment);
		setAttachForm(true)
	};
		
	const apply = (attachment) => {
		infoChanged(true, attachment);
    setAttachForm(false);
  };
	
  return (
	<div className={classes.root}>
	  <React.Fragment>
			<Box m={2} />
			<Typography color="primary">רצוי לצרף מסמכים שמחזקים את טענותיך.</Typography>
			<Typography>לדוגמה: הסכמים, מכתבים, תכתובות מייל ומסרונים, קבלות וחשבוניות על תשלומים, תצלומים של נזק שנתבע פיצוי בעדו וכד'.</Typography>
			<Box m={2} />
			{info && info.map(item => 
				<div key={item.id} className={classes.deleteButton}><IconButton onClick={(e)=>infoChanged(false, item)}><DeleteIcon /></IconButton><Link style={{cursor: 'pointer'}} onClick={()=>openForm(item)}>{item.name}</Link></div>
			)}
			{false && <Button variant="contained" component="label" className={classes.button} startIcon={<CloudUploadIcon />} >העלה קובץ<input type="file" onChange={(e)=>infoChanged(true, e.target.files[0])} hidden /></Button>}
			<Box m={4} />
			{info.length<6 && <Fab color="secondary" className={classes.addButton} onClick={()=>openForm(undefined)}><AddIcon /></Fab>}
			<Modal open={attachForm} onClose={()=>setAttachForm(false)}><Attachment ref={attachmentsRef} editAttachment={editAttachment} onClose={(attachment)=>apply(attachment)} /></Modal>
			<Box m={1} />
			<Typography><strong>ניתן להוסיף עד 6 נספחים לכתב התביעה,<br />כל נספח יכול להכיל מסמכים או אוסף תמונות.</strong></Typography>
				{false && <Typography><strong>אם אין בידכם מסמכים אלו כרגע אל דאגה! תוכלו לשלוח אותם בהמשך באימייל או בווטסאפ.</strong></Typography>}
		</React.Fragment>

	</div>
  );
}